<template>
  <div class="wordsEvaluation flex-column-between">
    <div class="breadcrumb flex-align-between">
      <div class="PageHeader">
        当前位置：<span>作品集</span> >
        <span>{{ $route.query.type == 0 ? "学生作品集" : "我的作品集" }}</span>
        >
        <span> 【{{ $route.query.worksName }}】任务作品</span>
      </div>
      <div class="back flex-align-between">
        <img :src="require('../../assets/task/return.png')" @click="goback" />
        <span @click="goback">返回</span>
      </div>
    </div>
    <div class="mianbox flex-align-between" v-if="imgArr.length>0">
      <div class="mianImg">
        <!-- :options="options" -->
        <!-- @fatherMethod="report" -->
        <!-- @getChildInfo="getChildInfo" -->
        <Viewers ref="Viewers" :options="$route.query.type" :imgArr="imgArr" @lantern="lantern"
          v-if="imgArr.length != 0">
        </Viewers>
        <div class="ReportPopup">
          <el-dialog title="" :show-close="false" :visible.sync="ReportPopup" :close-on-click-modal="false" center>
            <!-- v-if="reportType" -->
            <div class="mianshowreel">
              <div class="Evaluationsubject" v-if="reportData.title != null">
                【{{ reportData.title }}】作品测评报告
              </div>
              <div class="handleClose" @click="handleClose">
                <img src="@/assets/showreel/Close.png" />
              </div>
              <div class="mianshowreelSon">
                <div v-if="testStatus == 1" class="appraisalMianBox">
                  <div class="evaluate mianSon">
                    <div class="titlebox flex-align">
                      <img src="../../assets/assistant/pingjia.png" />
                      <span class="titleText">综合评价</span>
                    </div>
                    <div class="container flex-align-center">
                      <div class="containerT">
                        <div class="figurebox flex-align-between">
                          <div class="figure" v-for="(item, index) in 3" :key="index">
                            <img v-if="item == figureLevel[reportData.level]" src="../../assets/assistant/figure.png" />
                          </div>
                        </div>
                        <div class="progressBar"></div>
                        <div class="progressBarText flex-align-between">
                          <span style="color: #ffdb9c">待提升</span>
                          <span style="color: #f5aa49">良好</span>
                          <span style="color: #ed7e04">优秀</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="appraisal mianSon">
                    <div class="titlebox flex-align">
                      <img src="../../assets/assistant/ceping.png" />
                      <span class="titleText">整体测评</span>
                      <span class="subheading">（点击单字即可查看单字点评）</span>
                    </div>
                    <div class="container flex-align-center">
                      <div class="containerC flex-align-between">
                        <div class="prev carousel" :class="{ prevAff: prevType }" @click="slidePre"></div>
                        <div class="carouselMian">
                          <slider ref="slider" :options="sliderinit" @slide="slide">
                            <slideritem v-for="(item, index) in reportData.testList" :key="index">
                              <div class="textbox flex-column-center" :class="{
                                  textSonAff: appraisalIindex == index,
                                }" @click="switchover(index)">
                                <div class="">
                                  <div class="textSonT">
                                    <img src="../../assets/assistant/tian.png" />
                                    <img :src="item.imagePath" />
                                  </div>
                                  <div class="textSonImg flex-align-between">
                                    <div class="imgbox" v-for="(
                                        itemGrandson, indexGrandson
                                      ) in starList[
                                        reportData.testList[index].level
                                      ]" :key="indexGrandson">
                                      <img :src="itemGrandson" />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </slideritem>
                          </slider>
                        </div>
                        <div class="next carousel" :class="{
                            nextAff:
                              nextType && this.reportData.testList.length > 5,
                          }" @click="slideNext"></div>
                      </div>
                    </div>
                  </div>
                  <div class="remarkOn mianSon">
                    <div class="titlebox flex-align">
                      <img src="../../assets/assistant/remarkOn.png" />
                      <span class="titleText">单字点评</span>
                    </div>
                    <div class="container flex-align-center">
                      <div class="containerB flex-column-center">
                        <div class="comparison flex-align-between" v-if="
                            reportData != '' && reportData.testList.length != 0
                          ">
                          <div class="wordBox flex-column-center">
                            <span>原字</span>
                            <div class="wordImg" @click="amplification(0)">
                              <img :src="reportData.testList[appraisalIindex].wordCover" />
                            </div>
                          </div>
                          <div class="wordBox flex-column-center">
                            <span>待测字</span>
                            <div class="wordImg wordImgs" @click="amplification(1)">
                              <img src="../../assets/assistant/tian.png" />
                              <img :src="
                                  reportData.testList[appraisalIindex].imagePath
                                " />
                            </div>
                          </div>
                          <div class="wordBox flex-column-center" @click="amplification(2)">
                            <span>AI智能比对</span>
                            <div class="wordImg wordImgs">
                              <img src="../../assets/assistant/tian.png" />
                              <img :src="reportData.testList[appraisalIindex].wordShadowCover" />
                              <img :src="
                                  reportData.testList[appraisalIindex].imagePath
                                " />
                              <img :src=" reportData.testList[appraisalIindex].wordSkillCover" />
                            </div>
                          </div>
                        </div>
                        <div class="remark" v-if="
                            reportData != '' && reportData.testList.length != 0
                          ">
                          <!-- ---分数：{{reportData.testList[appraisalIindex].score}} -->
                          <span>评语</span>
                          <div class="remarkBox" v-if="reportData != ''">
                            <p v-html="
                                reportData.testList[appraisalIindex]
                                  .evaluateContent
                              "></p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="generated" v-else v-loading="loading" element-loading-text="测评生成中"
                  element-loading-spinner="el-icon-loading" element-loading-background="rgba(255, 255, 255, 0.9)"></div>
              </div>
            </div>
          </el-dialog>
        </div>
        <div class="qrcodeTip flex-align-between" v-if="true">
          <div class="category flex-column-center">
            <!-- <div class="flex-column-center" v-if="$route.query.type == 0">
              <img :src="
                  revealType
                    ? require('../../assets/showreel/projectionB.png')
                    : require('../../assets/showreel/projectionA.png')
                " @click="qrcodeShow" />
              <span @click="qrcodeShow">同步投影</span>
            </div> -->
            <div class="flex-column-center">
              <img :src="
                  appraisalType
                    ? require('../../assets/showreel/appraisalB.png')
                    : require('../../assets/showreel/appraisalA.png')
                " @click="appraisalShow" />
              <span @click="appraisalShow">AI测评</span>
            </div>
          </div>
          <div class="qrcodeBox" v-if="$route.query.type == 0">
            <div class="qrcodeIconDel">
              <i class="el-icon-close" @click="revealType = false"></i>
            </div>
            <div class="qrcodeL" :class="{ qrcodeHidden: revealType }">
              <div class="qrcodeDom flex-align">
                <div class="qrcodeImg flex-align-center">
                  <img src="@/assets/assistant/qrcode.jpg" />
                </div>
                <div class="qrcodetext flex-column">
                  <span>微信扫描二维码，</span>
                  <span>打开小程序</span>
                </div>
              </div>
              <div class="text flex-align-center">
                <div class="textGuidance flex-column-between">
                  <div>教师登录“首师优字教师端小程序”后，</div>
                  <div>
                    点击
                    <img src="../../assets/assistant/camera.png" />同步投影使用。同步投影 使用
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="workR" :class="[{ workRAff: broadsideType }, { workRW: delStatus }]">
        <div class="packUp flex-align-center" @click="packUp">
          <img :src="broadsideType ? img2 : img1" />
        </div>
        <div class="workImgList">
          <div class="workImgbox flex-align-center" :class="{ workImgAff: viewerVal == index }"
            v-for="(item, index) in imgArr" :key="index">
            <img class="label" :src="labels[item.level]" v-if="item.score > 0" />
            <div class="flex-column-center">
              <div class="Img" @click="pitchOn(index, item.id, item.status)">
                <img :src="item.img2" />
              </div>
              <div class="ImgName" style="color: #999999">{{item.bindUserName}}</div>
            </div>
            <div class="delwords flex-column-center" v-if="delStatus">
              <img src="@/assets/assistant/del.png" @click="
                  $route.query.type == 0
                    ? deleteTestList(item.id, index)
                    : deleteQQTestList(item.id, index)
                " />
              <span>删除</span>
            </div>
          </div>
        </div>
        <div class="workBtn flex-align-center" v-if="$route.query.type == 0">
          <div class="screen" @click="screen">筛选</div>
          <div class="operation flex-align-center" :class="{ workBtntype: workBtntype }" @click="Administration">
            {{ workBtntype ? "取消" : "管理" }}
          </div>
        </div>
      </div>
    </div>
    <div class="placeholder flex-column-center" v-else>
      <div></div>
      <span>暂无内容</span>
    </div>
    <div class="base"></div>
    <div class="commentImg">
      <!-- width="30%" -->
      <el-dialog :visible.sync="commenVisible" center :show-close="false" :modal-append-to-body="false">
        <div class="commentImgbox flex-align-between">
          <div class="prev carousel" :class="{ prevAff: popupIndex == 0 }" @click="slidePrePop"></div>
          <div class="classpopBox flex-align-center">
            <div class="classpopBoxImg1 classpopBoxImg" v-if="popupIndex == 0">
              <img :src="amplifier[0]" />
            </div>
            <div class="classpopBoxImg2 classpopBoxImg" v-if="popupIndex == 1">
              <img src="../../assets/assistant/tian.png" />
              <img :src="amplifier[1]" />
            </div>
            <div class="classpopBoxImg3 classpopBoxImg" v-if="popupIndex == 2">
              <img :src="amplifier[2][0]" />
              <img :src="amplifier[2][1]" />
              <img :src="amplifier[2][2]" />
              <img src="../../assets/assistant/tian.png" />
            </div>
          </div>
          <div class="next carousel" :class="{ nextAff: popupIndex == 2 }" @click="slideNextPop"></div>
          <div class="popClose" @click="commenVisible = false"></div>
        </div>
      </el-dialog>
    </div>
    <!-- 用户筛选 -->
    <div class="choice">
      <el-dialog title="筛选作者" :visible.sync="centerDialogVisible" center :show-close="true"
        :modal-append-to-body="false">
        <div class="choice_mian">
          <el-input placeholder="请输入手机/账号搜索" suffix-icon="el-icon-search" v-model="input1">
          </el-input>
          <div class="author">
            <div class="authorlist" v-if="true">
              <ul>
                <li class="flex-align-between" v-for="(item, index) in 5" :key="index">
                  <div class="information flex-align">
                    <img src="../../assets/myactivity/stu-boy.png" />
                    <div class="flex-column">
                      <span>侯磊</span>
                      <span>银川市第一实验中学</span>
                      <span>小学语文</span>
                    </div>
                  </div>
                  <div class="Viewworks flex-align-center">查看作品</div>
                </li>
              </ul>
            </div>
            <div class="EmptyData flex-column-center" v-else>
              <img src="../../assets/study/Empty.png" />
              <span>未查询到该用户</span>
            </div>
          </div>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
  import Viewers from "../attendclass/ViewerRecord.vue";
  import {
    slider,
    slideritem
  } from "vue-concise-slider";
  export default {
    data() {
      return {
        imgArr: [],
        broadsideType: false,
        img1: require("../../assets/assistant/packUp1.png"),
        img2: require("../../assets/assistant/packUp2.png"),
        viewerVal: 0,
        nowadayId: "",
        testStatus: null,
        labels: {
          1: require("../../assets/assistant/Toimprove.png"),
          2: require("../../assets/assistant/Toimprove.png"),
          3: require("../../assets/assistant/well.png"),
          4: require("../../assets/assistant/well.png"),
          5: require("../../assets/assistant/excellent.png"),
        },
        ReportPopup: false,
        //WebSocket
        websock: null, //建立的连接
        lockReconnect: false, //是否真正建立连接
        timeout: 5 * 1000, //20秒一次心跳
        timeoutObj: null, //心跳心跳倒计时
        serverTimeoutObj: null, //心跳倒计时
        timeoutnum: null, //断开 重连倒计时
        date: "",
        closeJunction: '{"msg":"心跳操作","code":209}',
        reportData: "",
        throttle: 0,
        resource: false,
        delStatus: false,
        workBtntype: false,
        appraisalIindex: 0,
        loading: false,
        figureLevel: {
          1: 1,
          2: 1,
          3: 2,
          4: 2,
          5: 3,
        },
        prevType: false,
        //文字轮播
        sliderinit: {
          currentPage: 0,
          tracking: false,
          thresholdDistance: 100,
          thresholdTime: 300,
          infinite: 6,
          slidesToScroll: 6,
          freeze: true, //禁止拖动
        },
        starList: {
          1: [
            require("../../assets/assistant/starB.png"),
            require("../../assets/assistant/starA.png"),
            require("../../assets/assistant/starA.png"),
            require("../../assets/assistant/starA.png"),
            require("../../assets/assistant/starA.png"),
          ],
          2: [
            require("../../assets/assistant/starB.png"),
            require("../../assets/assistant/starB.png"),
            require("../../assets/assistant/starA.png"),
            require("../../assets/assistant/starA.png"),
            require("../../assets/assistant/starA.png"),
          ],
          3: [
            require("../../assets/assistant/starB.png"),
            require("../../assets/assistant/starB.png"),
            require("../../assets/assistant/starB.png"),
            require("../../assets/assistant/starA.png"),
            require("../../assets/assistant/starA.png"),
          ],
          4: [
            require("../../assets/assistant/starB.png"),
            require("../../assets/assistant/starB.png"),
            require("../../assets/assistant/starB.png"),
            require("../../assets/assistant/starB.png"),
            require("../../assets/assistant/starA.png"),
          ],
          5: [
            require("../../assets/assistant/starB.png"),
            require("../../assets/assistant/starB.png"),
            require("../../assets/assistant/starB.png"),
            require("../../assets/assistant/starB.png"),
            require("../../assets/assistant/starB.png"),
          ],
        },
        prevType: false,
        nextType: false,
        amplifier: [
          [1],
          [1],
          [1, 2, 3]
        ],
        popupIndex: 0,
        commenVisible: false,
        revealType: false,
        appraisalType: false,
        centerDialogVisible: false,
        input1: "",
      };
    },
    components: {
      Viewers,
      slider,
      slideritem,
    },
    //方法
    methods: {
      goback() {
        this.imgArr = [];
        this.viewerVal = 0;
        this.$router.go(-1);
      },
      lantern(data) {
        data ? this.prev() : this.next();
      },
      //右侧作业列表收起切换
      packUp() {
        this.broadsideType = !this.broadsideType;
        this.delStatus = false;
        this.workBtntype = false;
      },
      //点击有车作业列表切换图片 -- 测试内容
      pitchOn(index, id, status) {
        this.appraisalIindex = 0;
        this.viewerVal = index;
        this.nowadayId = id;
        this.testStatus = status;
        let that = this;
        this.$nextTick(() => {
          that.$refs.Viewers.Index = that.viewerVal;
        });
      },
      async deleteTestList(id, index) {
        let data = {
          ids: id,
        };

        let resData = await this.$Api.Myclass.deleteTestList(data);
        if (resData.code == 200) {
          this.$message({
            message: "删除成功",
            center: true,
            type: "success",
          });
          this.IndexChange(index);
          this.imgArr.splice(index, 1);
          this.resource = this.imgArr.length == 0 ? false : true;
        }
      },
      async deleteQQTestList(id, index) {
        let data = {
          ids: id,
        };
        let resData = await this.$Api.Myclass.deleteQQTestList(data);
        if (resData.code == 200) {
          this.$message({
            message: "删除成功",
            center: true,
            type: "success",
          });
          this.IndexChange(index);
          this.imgArr.splice(index, 1);
          this.resource = this.imgArr.length == 0 ? false : true;
        }
      },
      IndexChange(index) {
        if (index == this.imgArr.length - 1 && index == this.viewerVal) {
          this.imgArr.splice(index, 1);
          this.viewerVal = index - 1;
          let that = this;
          this.$nextTick(() => {
            that.$refs.Viewers.Index = that.viewerVal;
          });
        }
      },
      //作品集管理

      Administration() {
        this.workBtntype = !this.workBtntype;
        this.delStatus = !this.delStatus;
      },
      //WebSocket
      initWebSocket() {
        //建立连接
        let wsuri =
        //   "apitest.lezhireading.com/api/learn/socket/projection/" +
          "yunapi.cnuzi.com/api/learn/socket/projection/" +
          sessionStorage.getItem("teacherId") +
          "/" +
          this.$route.query.wordId +
          "/" +
          this.$route.query.classId +
          "/" +
          this.$route.query.lessonId;
        //建立连接
        this.websock = new WebSocket("wss://" + wsuri);

        //连接成功
        this.websock.onopen = this.websocketonopen;

        //连接错误
        this.websock.onerror = this.websocketonerror;
        //接收信息
        this.websock.onmessage = this.websocketonmessage;
        //连接关闭
        // this.websock.onclose = this.websocketclose;
      },
      reconnect() {
        //重新连接
        var that = this;
        if (that.lockReconnect) {
          return;
        }
        that.lockReconnect = true;
        //没连接上会一直重连，设置延迟避免请求过多
        that.timeoutnum && clearTimeout(that.timeoutnum);
        that.timeoutnum = setTimeout(function () {
          //新连接
          that.initWebSocket();
          that.lockReconnect = false;
        }, 5000);
      },
      reset() {
        //重置心跳
        var that = this;
        //清除时间
        clearTimeout(that.timeoutObj);
        clearTimeout(that.serverTimeoutObj);
        //重启心跳
        that.start();
      },
      start() {
        //开启心跳
        var self = this;
        self.timeoutObj && clearTimeout(self.timeoutObj);
        self.serverTimeoutObj && clearTimeout(self.serverTimeoutObj);
        self.timeoutObj = setTimeout(function () {
          //这里发送一个心跳，后端收到后，返回一个心跳消息
          // console.log('self.websock.readyState', self.websock.readyState)
          if (self.websock.readyState == 1) {
            // console.log('self.date', self.date)
            // self.websock.send(self.date);
            self.websocketsend();
            // self.websock.send("heartbeat");
          } else {
            //否则重连
            self.reconnect();
          }
          self.serverTimeoutObj = setTimeout(function () {
            //超时关闭
            self.websock.close();
          }, self.timeout);
        }, self.timeout);
      },
      websocketonopen() {
        //连接成功事件
        this.websocketsend();
        //提示成功
        //开启心跳
        this.start();
      },
      websocketonerror(e) {
        //连接失败事件
        //错误
        //重连
        this.reconnect();
      },
      websocketclose(e) {
        //连接关闭事件
        //提示关闭
        //重连
        // this.reconnect();
      },
      websocketonmessage(event) {
        console.log('event--',event)
        //接收服务器推送的信息
        let data = JSON.parse(event.data);
        // console.log('//接收服务器推送的信息', event.data)
        if (this.throttle == 0 && data.data.code == 1002) {
          this.getWordImg();
          //   this.$route.query.type
          //     ? this.getWordImg(this.$route.query.lessonId)
          //     : this.goWordPersonal(this.$route.query.wordName);
          this.throttle = 1;
        }
        if (data.data.code == 1001) {
          this.getWordImg();
          //   this.$route.query.type
          //     ? this.getWordImg(this.$route.query.lessonId)
          //     : this.goWordPersonal(this.$route.query.wordName);
        }
        data.code = 200;
        //收到服务器信息，心跳重置
        this.reset();
      },
      websocketsend() {
        let msg = {
          code: 2001,
          data: JSON.stringify(this.$route.query),
        };
        // this.date = JSON.stringify(msg);
        //向服务器发送信息
        // console.log('//向服务器发送信息', JSON.stringify(msg))
        this.websock.send(JSON.stringify(msg));
      },
      //学生作品列表
      async getWordImg() {
        let data = {
          wordId: this.$route.query.wordId,
          lessonId: this.$route.query.lessonId,
        };
        let resData = await this.$Api.Myclass.queryTeaUploadWorksList(data);
        console.log('----', resData)
        if (resData.data.length == 0) {
          this.imgArr = [];
          this.resource = false;
          return;
        }

        this.resource = true;
        this.imgArr = [];
        for (let i = 0; i < resData.data.length; i++) {
          let angle =
            resData.data[i].params != null ?
            JSON.parse(resData.data[i].params).deg :
            0;
          this.imgArr.push({
            img1: resData.data[i].filePath,
            img2: resData.data[i].thumbFilePath,
            name: resData.data[i].bindUserName,
            id: resData.data[i].id,
            level: resData.data[i].level,
            status: resData.data[i].status,
            deg: angle,
            L: null,
            T: null,
            multiples: 1,
            score: resData.data[i].score,
          });
        }
        this.imgArr = this.imgArr.reverse();
        // console.log(" this.imgArr", this.imgArr);
        if (this.resource) this.estimate();
      },
      //我的作品集合
      async goWordPersonal() {
        console.log(this.$route.query)
        let data;
        if (this.$route.query.stageId) {
          data = {
            role: this.$route.query.role,
            stageId: this.$route.query.stageId
          };
        } else {
          data = {
            name: this.$route.query.workName,
            role: 1,
            activityId:this.$route.query.activityId
          };
        }

        let resData = await this.$Api.Myclass.getQQTestDetail(data);
        console.log('--+++--', resData)

        if (resData.data.length == 0) {
          this.imgArr = [];
          // this.resource = false;
          return;
        }

        this.resource = true;
        this.imgArr = [];
        for (let i = 0; i < resData.data.length; i++) {
          let angle =
            resData.data[i].params != null ?
            JSON.parse(resData.data[i].params).deg :
            0;
          this.imgArr.push({
            img1: resData.data[i].filePath,
            img2: resData.data[i].thumbFilePath,
            name: resData.data[i].bindUserName,
            id: resData.data[i].id,
            level: resData.data[i].level,
            status: resData.data[i].status,
            deg: angle,
            L: null,
            T: null,
            multiples: 1,
            score: resData.data[i].score,
          });
        }
        this.imgArr = this.imgArr.reverse();
        if (this.resource) this.estimate();
      },
      //判断当前索引
      estimate() {
        let that = this;
        let result = this.imgArr.findIndex((item) => {
          return item.id == that.nowadayId;
        });
        if (result != -1) {
          this.viewerVal = result;
        } else if (this.viewerVal > this.imgArr.length - 1) {
          this.viewerVal = this.imgArr.length - 1;
        }
        this.nowadayId = this.imgArr[this.viewerVal].id;
        this.testStatus = this.imgArr[this.viewerVal].status;
        if (this.resource) {
          that.$nextTick(() => {
            //     console.log(" this.resource", this.resource);
            this.$refs.Viewers.Index = this.viewerVal;
          });
        }
      },
      //学生测评报告
      async getreport() {
        let data = {
          uploadId: this.nowadayId,
        };
        let resData = await this.$Api.Myclass.getTeachAssessReport(data);
        this.reportData = resData.data;
        if (this.reportData.testList.length != 0) {
          this.spreadPdf(0);
        }
      },
      //个人测评报告
      async getAssessReport() {
        let data = {
          uploadId: this.nowadayId,
          type: 3,
        };
        let resData = await this.$Api.Myclass.getAssessReport(data);
        this.reportData = resData.data;
        if (this.reportData.testList.length != 0) {
          this.spreadPdf(0);
        }
      },
      //向父组件图片放大弹窗传递数据
      spreadPdf(value) {
        let spread;
        // if (this.$route.query.type == 1) {
          spread = [
            [this.reportData.testList[value].wordCover],
            [this.reportData.testList[value].imagePath],
            [
              this.reportData.testList[value].wordShadowCover,
              this.reportData.testList[value].imagePath,
              this.reportData.testList[value].wordSkillCover,
            ],
          ];
        // } else {
        //   spread = [
        //     [this.reportData.wordCover],
        //     [this.reportData.testList[value].imagePath],
        //     [
        //       this.reportData.wordShadowCover,
        //       this.reportData.testList[value].imagePath,
        //       this.reportData.wordSkillCover,
        //     ],
        //   ];
        // }

        this.amplifier = spread;
        // console.log("this.amplifier", this.amplifier);
      },
      //图片放大
      amplification(index) {
        this.commenVisible = true;
        this.popupIndex = index;
      },
      prev() {
        if (this.viewerVal > 0) {
          this.viewerVal--;
          this.pitchOn(
            this.viewerVal,
            this.imgArr[this.viewerVal].id,
            this.imgArr[this.viewerVal].status
          );
        }
      },
      next() {
        if (this.viewerVal < this.imgArr.length - 1) {
          this.viewerVal++;
          this.pitchOn(
            this.viewerVal,
            this.imgArr[this.viewerVal].id,
            this.imgArr[this.viewerVal].status
          );
        }
      },
      slidePrePop() {
        if (this.popupIndex > 0) {
          this.popupIndex--;
        }
      },
      slideNextPop() {
        if (this.popupIndex < 2) {
          this.popupIndex++;
        }
      },
      handleClose() {
        this.ReportPopup = false;
        this.appraisalType = false;
      },
      //测评切换
      report() {
        this.$route.query.type == 0 ? this.getreport() : this.getAssessReport();
        // if (this.$route.query.type == 0) {
        //   this.getreport();
        // } else {
        //   this.getAssessReport();
        // }

        if (this.testStatus == 0) {
          this.reportType = true;
          this.ReportPopup = true;
          this.loading = true;
        } else if (this.testStatus == 1) {
          this.reportType = true;
          this.ReportPopup = true;
          this.loading = false;
        } else if (this.testStatus == 2) {
          this.$confirm("报告生成失败,请联系管理员", "提示", {
              confirmButtonText: "确定",
              type: "success",
              center: true,
              showCancelButton: false,
            })
            .then(() => {
              this.appraisalType = false;
            })
            .catch(() => {
              this.appraisalType = false;
            });
        } else if (this.testStatus == 3) {
          this.$confirm("该内容非本课作品，请检查后重新提交", "提示", {
              confirmButtonText: "确定",
              type: "success",
              center: true,
              showCancelButton: false,
            })
            .then(() => {
              this.appraisalType = false;
            })
            .catch(() => {
              this.appraisalType = false;
            });
        } else if (this.testStatus == 4) {
          this.$confirm("未识别到要测评的生字，请重新拍摄", "提示", {
              confirmButtonText: "确定",
              type: "success",
              center: true,
              showCancelButton: false,
            })
            .then(() => {
              this.appraisalType = false;
            })
            .catch(() => {
              this.appraisalType = false;
            });
        } else if (this.testStatus == 5) {
          this.$confirm("请将写字单拍摄完整（包含外框）后，再进行测评", "提示", {
              confirmButtonText: "确定",
              type: "success",
              center: true,
              showCancelButton: false,
            })
            .then(() => {
              this.appraisalType = false;
            })
            .catch(() => {
              this.appraisalType = false;
            });
        } else if (this.testStatus == 6) {
          this.$confirm("该字暂不支持测评", "提示", {
              confirmButtonText: "确定",
              type: "success",
              center: true,
              showCancelButton: false,
            })
            .then(() => {
              this.appraisalType = false;
            })
            .catch(() => {
              this.appraisalType = false;
            });
        }
      },
      //测评
      slide(data) {
        if (data.currentPage == 0) {
          this.nextType = true;
          this.prevType = false;
        } else if (data.currentPage > this.reportData.testList.length - 6) {
          this.nextType = false;
          this.prevType = true;
        } else {
          this.nextType = true;
          this.prevType = true;
        }
      },

      //测试上下一页
      slideNext() {
        this.$refs.slider.$emit("slideNext");
      },
      //测试上一页
      slidePre() {
        this.$refs.slider.$emit("slidePre");
      },
      //整体测评点击切换
      switchover(index) {
        this.appraisalIindex = index;
        this.spreadPdf(index);
      },
      qrcodeShow() {
        this.revealType = !this.revealType;
      },
      appraisalShow() {
        this.appraisalType = !this.appraisalType;
        this.report();
      },
      // 作者搜索
      screen() {
        this.centerDialogVisible = true;
      },
    },
    //生命周期 - 创建完成（访问当前this实例）
    created() {},
    //生命周期 - 挂载完成（访问DOM元素）
    mounted() {
      let _this = this;
      this.$route.query.type == 0 ?
      this.getWordImg()
        // this.initWebSocket() //   this.getWordImg()
        :
        this.goWordPersonal();
    },
  };
</script>
<style lang='less' scoped>
  @import "./less/studywordsEvaluation";

  .carouselMian {
    height: 140px;
    width: 810px;

    /deep/.slider-wrapper {
      margin: 0 2px;
    }

    /deep/.slider-item {
      width: 112.4px !important;
      height: 135px !important;
      margin-right: 26px;
    }

    /deep/.slider-pagination {
      display: none;
    }
  }
</style>